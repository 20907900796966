<template>
  <base-section id="games">
    <base-section-heading title="Games">
      Play all the Fifth Spark games that we've made. Check back or subscribe to the channel to get updates when we publish more awesome games!
    </base-section-heading>
    <v-container class="grey lighten-5">
      <v-row no-gutters>
        <v-col v-for="n in 1" :key="n" cols="12" sm="3">
          <v-card class="pa-2 ma-1" :loading="loading" outlined tile>
            <template slot="progress">
              <v-progress-linear color="primary" height="10" indeterminate></v-progress-linear>
            </template>

            <v-img :src="require('@/assets/games/oneflypig.png')" contain></v-img>

            <v-card-title>One Fly Pig</v-card-title>

            <v-card-subtitle>
              <div>Collect the blue coins and help Keith Pig avoid the manic missiles and crazy fruit!</div>
            </v-card-subtitle>

            <v-divider class="mx-4"></v-divider>
            <v-card-actions>
              <v-btn color="primary" block @click="PopUp('/games/oneflypig/index.html')" >
                Play
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      selection: 1,
    }),

    methods: {
      reserve() {
        this.loading = true
        setTimeout(() => (this.loading = false), 2000)
      },

      PopUp(url) {
        var leftPosition, topPosition, width, height;
        width = window.screen.width * .5;
        height = window.screen.height * .52;
        //Allow for borders.
        leftPosition = (window.screen.width / 2) - ((width / 2));
        //Allow for title and status bars.
        topPosition = (window.screen.height / 2) - ((height / 2) + 50);
        //Open the window.
        window.open(url, "One Fly Pig",
          "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" +
          leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY=" +
          topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
      }
    },
  }

 
</script>